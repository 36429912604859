import {Component, OnDestroy, OnInit} from "@angular/core";
import { faHouse, faBoxesStacked, faFiles, faBoxCheck, faCashRegister, faLeftFromBracket, faUserTie, faStore } from '@fortawesome/sharp-thin-svg-icons';
import {NavigationEnd, Router} from "@angular/router";
import {BehaviorSubject, interval, last, lastValueFrom, Observable, of, startWith, Subject, Subscription, switchMap, takeUntil} from "rxjs";
import {select, Store} from "@ngrx/store";
import {logout} from "../../../store/actions/logout.actions";
import {selectUser, User, UserState} from "../../../store/states/user.state";
import { UserRole } from "../../../common/api-dtos/responses/User.api.dto";
import { FilesState, selectFilesSelector } from "../../../store/states/files.state";
import { getTotalPendingDocuments } from "../../../store/actions/files.action";


@Component({
  templateUrl: "./logged-in-layour.component.html",
  selector: "app-logged-in-layout",
  styleUrls: ["./logged-in-layour.component.scss"],
})
export class LoggedInLayout implements OnDestroy {
  isLoggedIn = true;

  faHouse = faHouse;
  faBoxesStacked = faBoxesStacked;
  faFiles = faFiles;
  faBoxCheck = faBoxCheck;
  faCashRegister = faCashRegister;
  faUserTie = faUserTie;
  faLeftFromBracket = faLeftFromBracket;
  faStore = faStore;
  
  isAdmin$ : BehaviorSubject<boolean> = new BehaviorSubject(false);
  unsubscribe$ : Subject<void> = new Subject();
  user$ : Observable<UserState>;

  fileState$ : Observable<FilesState> = this.store.pipe(select(selectFilesSelector));

  private refreshSubscription!: Subscription;

  public burgerMenuOpen = false;

  ngOnDestroy() {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

  openMobileMenu() {
    this.burgerMenuOpen = true;
  }

  closeMobileMenu() {
    this.burgerMenuOpen = false;
  }

  constructor(
      private readonly router: Router,
      private readonly store: Store,
  ) {
    this.user$ = this.store.pipe(select(selectUser)).pipe(takeUntil(this.unsubscribe$))

    this.user$.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(user => {
      if (user?.user?.role === UserRole.ADMIN || user?.user?.role === UserRole.OPERATIONS) {
        this.isAdmin$.next(true)
      } else {
        this.isAdmin$.next(false)
      }
    });

    this.refreshSubscription = interval(180000).pipe(
      startWith(0),
      switchMap(() => {
        this.getPendingFiles();
        return this.fileState$;
      }),
      takeUntil(this.unsubscribe$)
    ).subscribe();
  }

  getPendingFiles() {
    this.store.dispatch(getTotalPendingDocuments())
  }

  isNavHidden = false;

  logout() {
    this.store.dispatch(logout())
  }

  isSelectedUrl(params: string): boolean {
    return window.location.pathname.includes(params)
  }

  getItemClasses(route: string): any {
    return {
      ...this.NAV_ITEM_COMMON_CLASSES,
      'active': this.isSelectedUrl(route)
    }
  }

  readonly NAV_ITEM_COMMON_CLASSES = {
    flex: true,
    'align-items-center': true,
    'cursor-pointer': true,
    'p-3': true,
    'border-round': true,
    'text-700': true,
    'hover:surface-100': true,
    'transition-duration-5': true,
    'transition-colors': true,
  }

}
