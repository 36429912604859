import {createAction, props} from "@ngrx/store";
import {SearchParametersDto} from "../../common/api-dtos/requests/search-parameters.dto";
import { TableSaleOrder } from "../states/sale-orders.state";
import { Task } from "../states/tasks.state";
import { TaskResult } from "../../administration/components/task-actions/task-actions.component";
import { ResumeParsePriceFile } from "../../common/api-dtos/requests/resume-parse-price-file.dto";
import { ResumeParseStockFile } from "../../common/api-dtos/requests/resume-parse-stock-file.dto";
import { CreateStoreItemDto } from "../../common/api-dtos/requests/create-store-item.dto";

export const GET_STORE_PRODUCTS = '[Store] Get Products';
export const GET_STORE_PRODUCTS_SUCCESS = '[Store] Get Products Success';
export const GET_STORE_PRODUCTS_FAILURE = '[Store] Get Products Failure';

export const getStoreProducts = createAction(
  GET_STORE_PRODUCTS,
  props<{ params: SearchParametersDto }>()
)
export const getStoreProductsSuccess = createAction(
  GET_STORE_PRODUCTS_SUCCESS,
  props<{ products: any[], totalProducts: number }>()
)
export const getStoreProductsFailure = createAction(GET_STORE_PRODUCTS_FAILURE)

export const CLEAR_STORE_PRODUCTS = '[Store] Clear Products';
export const clearStoreProducts = createAction(CLEAR_STORE_PRODUCTS);

export const CREATE_STORE_PRODUCT = '[Store] Create Product';
export const CREATE_STORE_PRODUCT_SUCCESS = '[Store] Create Product Success';
export const CREATE_STORE_PRODUCT_FAILURE = '[Store] Create Product Failure';

export const createStoreProduct = createAction(
  CREATE_STORE_PRODUCT,
  props<{ product: CreateStoreItemDto }>()
)
export const createStoreProductSuccess = createAction(CREATE_STORE_PRODUCT_SUCCESS)
export const createStoreProductFailure = createAction(CREATE_STORE_PRODUCT_FAILURE)

export const DELETE_STORE_PRODUCT = '[Store] Delete Product';
export const DELETE_STORE_PRODUCT_SUCCESS = '[Store] Delete Product Success';
export const DELETE_STORE_PRODUCT_FAILURE = '[Store] Delete Product Failure';

export const deleteStoreProduct = createAction(
  DELETE_STORE_PRODUCT,
  props<{ id: string }>()
)
export const deleteStoreProductSuccess = createAction(DELETE_STORE_PRODUCT_SUCCESS)
export const deleteStoreProductFailure = createAction(DELETE_STORE_PRODUCT_FAILURE)