import {createAction, props} from "@ngrx/store";
import {SearchParametersDto} from "../../common/api-dtos/requests/search-parameters.dto";
import { File } from "../states/files.state";
import { FilesFilterModel } from "../../common/models/files-filter.model";

export const GET_FILES = '[Files] Get Files';
export const GET_FILES_SUCCESS = '[Files] Get Files Success';
export const GET_FILES_FAILURE = '[Files] Get Files Failure';
export const CLEAR_FILES = '[Files] Get Files Clear';

export const getFiles = createAction(
  GET_FILES,
  props<{ searchParameters: SearchParametersDto, filters?: FilesFilterModel }>()
);
export const getFilesSuccess = createAction(
  GET_FILES_SUCCESS,
  props<{ files: File[], totalFiles: number }>()
);
export const getFilesFailure = createAction(GET_FILES_FAILURE);
export const clearFiles = createAction(CLEAR_FILES);

export const GET_TOTAL_PENDING_DOCUMENTS = '[Files] Get Total Pending Documents';
export const GET_TOTAL_PENDING_DOCUMENTS_SUCCESS = '[Files] Get Total Pending Documents Success';
export const GET_TOTAL_PENDING_DOCUMENTS_FAILURE = '[Files] Get Total Pending Documents Failure';

export const getTotalPendingDocuments = createAction(GET_TOTAL_PENDING_DOCUMENTS);
export const getTotalPendingDocumentsSuccess = createAction(
  GET_TOTAL_PENDING_DOCUMENTS_SUCCESS,
  props<{ totalPendingDocuments: number }>()
);
export const getTotalPendingDocumentsFailure = createAction(GET_TOTAL_PENDING_DOCUMENTS_FAILURE);