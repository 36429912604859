import {createFeatureSelector, createSelector} from "@ngrx/store";
import {AppState} from "./index";
import { UserRole } from "../../common/api-dtos/responses/User.api.dto";

export interface User {
  username: string;
  email: string;
  role: UserRole;
}

export interface UserState {
  user: User | null;
}

export const initialUserState: UserState = {
  user: null,
};


export const SELECT_USER = 'user'
export const selectUserStates = createFeatureSelector<UserState>(SELECT_USER);

export const selectUser  = createSelector(
  selectUserStates,
  (state: UserState) => state
);