import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { SearchParametersDto } from "../../common/api-dtos/requests/search-parameters.dto";
import { FilesTableDto } from "../../common/api-dtos/responses/files.dto";
import { Observable } from "rxjs";
import { HttpRequestHelper } from "../../common/helpers/http-request.helper";
import { FileCreateResponseDto, FilesCreateDto } from "../../common/api-dtos/requests/create-file.dto";
import { ProcessUploadFileDto } from "../../common/api-dtos/requests/process-file.dto";
import { ParsedPriceFileDto } from "../../common/api-dtos/responses/parsed-price-file.dto";
import { ParsedStockFileDto } from "../../common/api-dtos/responses/parsed-stock-file.dto";
import { FilesFilterModel } from "../../common/models/files-filter.model";

@Injectable({ providedIn: 'root' })
export class FilesService {

    constructor(
        private readonly http: HttpClient
    ) {}

    private readonly BASE_URL = environment.BACKEND_URL || "localhost:3000"
    private readonly FILES_ENDPOINT = `${this.BASE_URL}/v1/files`

    getFiles(searchTerm: SearchParametersDto, filters?: FilesFilterModel): Observable<FilesTableDto> {
        const headers = HttpRequestHelper.getHttpHeaders(searchTerm);
        const cleanedFilters = filters 
            ? Object.fromEntries(Object.entries(filters).filter(([_, v]) => v !== undefined)) 
            : {};
        const params = { ...searchTerm, ...cleanedFilters };
        const options = HttpRequestHelper.getHttpOptions(headers, params);
        return this.http.get<FilesTableDto>(`${this.FILES_ENDPOINT}`, options);
    }

    getUploadSignedUrl(request: FilesCreateDto) : Observable<FileCreateResponseDto> {
        const headers = HttpRequestHelper.getHttpHeaders(request);
        const options = HttpRequestHelper.getHttpOptions(headers);
        return this.http.post<FileCreateResponseDto>(`${this.FILES_ENDPOINT}/get-upload-signed-url`, request, options);
    }

    uploadFileToBucket(uploadUrl: string, file: File) {
        return this.http.put(uploadUrl, file);
    }

    processUploadedFile(request: ProcessUploadFileDto) : Observable<void> {
        const headers = HttpRequestHelper.getHttpHeaders(request);
        const options = HttpRequestHelper.getHttpOptions(headers);
        return this.http.post<void>(`${this.FILES_ENDPOINT}/process-upload-file`, request, options);
    }

    getPriceParseFile(id: string) {
        const headers = HttpRequestHelper.getHttpHeaders();
        const options = HttpRequestHelper.getHttpOptions(headers);
        return this.http.get<ParsedPriceFileDto>(`${this.FILES_ENDPOINT}/processed/price/${id}`, options);
    }

    getStockParseFile(id: string) {
        const headers = HttpRequestHelper.getHttpHeaders();
        const options = HttpRequestHelper.getHttpOptions(headers);
        return this.http.get<ParsedStockFileDto>(`${this.FILES_ENDPOINT}/processed/stock/${id}`, options);
    }

    getTotalPendingDocuments() {
        const headers = HttpRequestHelper.getHttpHeaders();
        const options = HttpRequestHelper.getHttpOptions(headers);
        return this.http.get<{ totalPendingDocuments: number }>(`${this.FILES_ENDPOINT}/total-pending-documents`, options);
    }

}