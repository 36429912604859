import { createFeatureSelector, createSelector } from "@ngrx/store";

export interface File {
    id: string;
    type: string;
    status: string;
    createdBy: string;
    createdAt: Date;
}

export interface FilesState {
    total: number;
    totalPendingDocuments: number;
    files: File[];
    loading: boolean,
    error: string | null,
}

export const initialFilesState: FilesState = {
    files: [],
    total: 0,
    totalPendingDocuments: 0,
    loading: false,
    error: null
}

export const SELECT_FILES = 'files'
export const selectFilesState =
  createFeatureSelector<FilesState>(SELECT_FILES);

export const selectFilesSelector = createSelector(
    selectFilesState,
  (state: FilesState) => state
);