import { createAction, props } from '@ngrx/store';
import { CredentialsActionDto } from '../../common/api-dtos/requests/credentials.dto';
import {UserApiDto} from "../../common/api-dtos/responses/User.api.dto";

export const LOG_OUT_ACTION = '[Log Out] Logout';
export const LOG_OUT_SUCCESS_ACTION = '[Log Out] Log Out Success';

export const logout = createAction(LOG_OUT_ACTION);

export const logOutSuccess = createAction(LOG_OUT_SUCCESS_ACTION,);
