import { createFeatureSelector, createSelector } from "@ngrx/store";
import { PurchaseOrderStatus } from "../../common/api-dtos/responses/purchase-order.dto";

export interface PurchaseOrderItem {
    product: {
        id: string;
        name: string;
        sku: string;
    }
    stockPrice: number;
    providerPrice?: number | null;
    quantity: number;
}

export interface PurchaseOrder {
    id: string;
    createdBy: {
        id: string;
        name: string;
    }
    company: {
        id: string;
        name: string;
    }
    provider: {
        id: string;
        name: string;
    } | null;
    items: PurchaseOrderItem[];
    status: PurchaseOrderStatus;
    createdAt: Date;
    updatedAt: Date;
}

export interface PurchaseOrderState {
    orders: PurchaseOrder[];
    individualOrder: PurchaseOrder | null;
    loading: boolean,
    error: string | null,
    totalOrders: number;
}

export const initialPurchaseOrderState: PurchaseOrderState = {
    orders: [],
    individualOrder: null,
    loading: false,
    error: null,
    totalOrders: 0
}

export const SELECT_ORDERS_STATE = 'purchaseOrders'
export const selectOrdersState =
    createFeatureSelector<PurchaseOrderState>(SELECT_ORDERS_STATE);

export const selectOrders = createSelector(
    selectOrdersState,
    (state: PurchaseOrderState) => ({ loading: state?.loading, orders: state?.orders, totalOrders: state?.totalOrders })
);
export const selectIndividualOrder = createSelector(
    selectOrdersState,
    (state: PurchaseOrderState) => ({ loading: state?.loading, order: state?.individualOrder })
);