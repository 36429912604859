import {createAction, props} from "@ngrx/store";
import {SearchParametersDto} from "../../common/api-dtos/requests/search-parameters.dto";
import {ItemDto} from "../../common/api-dtos/responses/products.dto";
import {GroupedProduct} from "../states/grouped-products.state";

export const GET_GROUPED_PRODUCTS = '[Grouped Products] Get Grouped Products';
export const GET_GROUPED_PRODUCTS_SUCCESS = '[Grouped Products] Get Grouped Products Success';
export const GET_GROUPED_PRODUCTS_FAILURE = '[Grouped Products] Get Grouped Products Failure';
export const CLEAR_GROUPED_PRODUCTS_LIST = '[Grouped Products] Clear';

export const getGroupedProducts = createAction(
  GET_GROUPED_PRODUCTS,
  props<{ searchParameters: SearchParametersDto }>()
);
export const getGroupedProductsSuccess = createAction(
  GET_GROUPED_PRODUCTS_SUCCESS,
  props<{ products: GroupedProduct[], totalProducts: number }>()
);
export const getGroupedProductsFailure = createAction(
  GET_GROUPED_PRODUCTS_FAILURE
);
export const clearGroupedProductsList = createAction(CLEAR_GROUPED_PRODUCTS_LIST);
