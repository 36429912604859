import {createAction, props} from "@ngrx/store";
import { PurchaseOrder } from "../states/purchase-order.state";
import { UpdatePurchaseOrderDto } from "../../common/api-dtos/requests/update-purchase-order.dto";

export const GET_PURCHASE_ORDERS = '[Purchase Orders] Get Purchase Orders';
export const GET_PURCHASE_ORDERS_SUCCESS = '[Purchase Orders] Get Purchase Orders Success';
export const GET_PURCHASE_ORDERS_FAILURE = '[Purchase Orders] Get Purchase Orders Failure';
export const CLEAN_PURCHASE_ORDERS = '[Purchase Orders] Clean Purchase Orders';

export const getPurchaseOrdersList = createAction(
    GET_PURCHASE_ORDERS,
  props<{ limit: number, offset: number }>()
);
export const getPurchaseOrdersListSuccess = createAction(
    GET_PURCHASE_ORDERS_SUCCESS,
  props<{ 
    orders: PurchaseOrder[],
    totalOrders: number
  }>()
);
export const getPurchaseOrdersListFailure = createAction(GET_PURCHASE_ORDERS_FAILURE);
export const cleanPurchaseOrdersList = createAction(CLEAN_PURCHASE_ORDERS);

export const GET_SINGLE_PURCHASE_ORDER = '[Purchase Orders] Get Single Purchase Order';
export const GET_SINGLE_PURCHASE_ORDER_SUCCESS = '[Purchase Orders] Get Single Purchase Order Success';
export const GET_SINGLE_PURCHASE_ORDER_FAILURE = '[Purchase Orders] Get Single Purchase Order Failure';

export const getSinglePurchaseOrder = createAction(
    GET_SINGLE_PURCHASE_ORDER,
  props<{ id: string }>()
);
export const getSinglePurchaseOrderSuccess = createAction(
    GET_SINGLE_PURCHASE_ORDER_SUCCESS,
  props<{ order: PurchaseOrder }>()
);
export const getSinglePurchaseOrderFailure = createAction(GET_SINGLE_PURCHASE_ORDER_FAILURE);

export const CREATE_PURCHASE_ORDER = '[Purchase Orders] Create Purchase Order';
export const CREATE_PURCHASE_ORDER_SUCCESS = '[Purchase Orders] Create Purchase Order Success';
export const CREATE_PURCHASE_ORDER_FAILURE = '[Purchase Orders] Create Purchase Order Failure';

export const createPurchaseOrder = createAction(
  CREATE_PURCHASE_ORDER,
  props<{ fallbackOrder?: UpdatePurchaseOrderDto, redirectToOrder?: boolean }>()
);
export const createPurchaseOrderSuccess = createAction(
    CREATE_PURCHASE_ORDER_SUCCESS,
  props<{ order: PurchaseOrder }>()
);
export const createPurchaseOrderFailure = createAction(CREATE_PURCHASE_ORDER_FAILURE);

export const UPDATE_PURCHASE_ORDER = '[Purchase Orders] Update Purchase Order';
export const UPDATE_PURCHASE_ORDER_SUCCESS = '[Purchase Orders] Update Purchase Order Success';
export const UPDATE_PURCHASE_ORDER_FAILURE = '[Purchase Orders] Update Purchase Order Failure';

export const updatePurchaseOrder = createAction(
    UPDATE_PURCHASE_ORDER,
  props<{ id: string, order: UpdatePurchaseOrderDto }>()
);
export const updatePurchaseOrderSuccess = createAction(
    UPDATE_PURCHASE_ORDER_SUCCESS,
  props<{ order: PurchaseOrder }>()
);
export const updatePurchaseOrderFailure = createAction(UPDATE_PURCHASE_ORDER_FAILURE);

export const PROCESS_PURCHASE_ORDER = '[Purchase Orders] Process Purchase Order';
export const PROCESS_PURCHASE_ORDER_SUCCESS = '[Purchase Orders] Process Purchase Order Success';
export const PROCESS_PURCHASE_ORDER_FAILURE = '[Purchase Orders] Process Purchase Order Failure';

export const processPurchaseOrder = createAction(
    PROCESS_PURCHASE_ORDER,
  props<{ id: string }>()
);
export const processPurchaseOrderSuccess = createAction(
    PROCESS_PURCHASE_ORDER_SUCCESS,
  props<{ order: PurchaseOrder }>()
);
export const processPurchaseOrderFailure = createAction(PROCESS_PURCHASE_ORDER_FAILURE);

export const COMPLETE_PURCHASE_ORDER = '[Purchase Orders] Complete Purchase Order';
export const COMPLETE_PURCHASE_ORDER_SUCCESS = '[Purchase Orders] Complete Purchase Order Success';
export const COMPLETE_PURCHASE_ORDER_FAILURE = '[Purchase Orders] Complete Purchase Order Failure';

export const completePurchaseOrder = createAction(
    COMPLETE_PURCHASE_ORDER,
  props<{ id: string }>()
);
export const completePurchaseOrderSuccess = createAction(
    COMPLETE_PURCHASE_ORDER_SUCCESS,
  props<{ order: PurchaseOrder }>()
);
export const completePurchaseOrderFailure = createAction(COMPLETE_PURCHASE_ORDER_FAILURE);

export const CLEAR_INDIVIDUAL_PURCHASE_ORDER = '[Purchase Orders] Clear Individual Purchase Order';
export const clearIndividualPurchaseOrder = createAction(CLEAR_INDIVIDUAL_PURCHASE_ORDER);

export const DOWNLOAD_PURCHASE_ORDER_PDF = '[Purchase Orders] Download Purchase Order PDF';
export const DOWNLOAD_PURCHASE_ORDER_PDF_SUCCESS = '[Purchase Orders] Download Purchase Order PDF Success';
export const DOWNLOAD_PURCHASE_ORDER_PDF_FAILURE = '[Purchase Orders] Download Purchase Order PDF Failure';

export const downloadPurchaseOrderPdf = createAction(
    DOWNLOAD_PURCHASE_ORDER_PDF,
  props<{ id: string }>()
);
export const downloadPurchaseOrderPdfSuccess = createAction(
  DOWNLOAD_PURCHASE_ORDER_PDF_SUCCESS,
  props<{ url: string, fileName: string }>()
);
export const downloadPurchaseOrderPdfFailure = createAction(DOWNLOAD_PURCHASE_ORDER_PDF_FAILURE);