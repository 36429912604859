import { createFeatureSelector, createSelector } from "@ngrx/store";
import { SearchParametersDto } from "../../common/api-dtos/requests/search-parameters.dto";

export interface ParsePriceFile {
  id: string;
  status: string;
  createdBy: string;
  createdAt: Date;
  parsedData: ParsedPriceData[];
  provider: { id: string, name: string };
}

export interface ParsedPriceData {
  code: string;
  description: string;
  parsedPrice: number;
  stockPrice: number;
  isOnStock: boolean;
  stockItemId: string;
}

export interface ParsePriceFileState {
  file: ParsePriceFile | null;
  loading: boolean,
  error: string | null,
}

export const initialParsePriceFilesState: ParsePriceFileState = {
  file: null,
  loading: false,
  error: null
}

export const SELECT_PARSED_PRICE_FILE = 'parsedPriceFile'
export const selectParsedPriceFileState =
  createFeatureSelector<ParsePriceFileState>(SELECT_PARSED_PRICE_FILE);

export const selectParsedPriceFileSelector = createSelector(
  selectParsedPriceFileState,
  (state: ParsePriceFileState) => state
);