import {createAction, props} from "@ngrx/store";
import { ProductAugmentationState, ProviderAugmentationState } from "../states/dashboard.state";
import { HistoricalPriceMapDto } from "../../common/api-dtos/responses/product-historic-data.dto";
import { ChartData } from "../../common/api-dtos/responses/product-augmentation.dto";

export const GET_PROVIDERS_AUGMENTATION = '[Dashboard] Get Provider Augmentation';
export const GET_PROVIDERS_AUGMENTATION_SUCCESS = '[Dashboard] Get Provider Augmentation Success';
export const GET_PROVIDERS_AUGMENTATION_FAILURE = '[Dashboard] Get Provider Augmentation Failure';

export const GET_HISTORIAL_PRODUCT_PRICES = '[Dashboard] Get Historical Product Prices';
export const GET_HISTORIAL_PRODUCT_PRICES_SUCCESS = '[Dashboard] Get Historical Product Prices Success';
export const GET_HISTORIAL_PRODUCT_PRICES_FAILURE = '[Dashboard] Get Historical Product Prices Failure';
export const CLEAR_HISTORIAL_PRODUCT_PRICES = '[Dashboard] Clear Historical Product Prices';

export const GET_PRODUCTS_AUGMENTATION = '[Dashboard] Get Products Augmentation';
export const GET_PRODUCTS_AUGMENTATION_SUCCESS = '[Dashboard] Get Products Augmentation Success';
export const GET_PRODUCTS_AUGMENTATION_FAILURE = '[Dashboard] Get Products Augmentation Failure';
export const CLEAR_PRODUCTS_AUGMENTATION = '[Dashboard] Clear Products Augmentation';

export const getHistoricalProductPrices = createAction(GET_HISTORIAL_PRODUCT_PRICES, props<{ sku: string }>());
export const getHistoricalProductPricesSuccess = createAction(
    GET_HISTORIAL_PRODUCT_PRICES_SUCCESS,
    props<{ historicalProductPrices: HistoricalPriceMapDto }>()
);
export const getHistoricalProductPricesFailure = createAction(GET_HISTORIAL_PRODUCT_PRICES_FAILURE);
export const clearHistoricalProductPrices = createAction(CLEAR_HISTORIAL_PRODUCT_PRICES);

export const getProvidersAugmentation = createAction(GET_PROVIDERS_AUGMENTATION);
export const getProvidersAugmentationSuccess = createAction(
    GET_PROVIDERS_AUGMENTATION_SUCCESS,
    props<{ providersAugmentation: ProviderAugmentationState[][] }>()
);
export const getProvidersAugmentationFailure = createAction(GET_PROVIDERS_AUGMENTATION_FAILURE);

export const getProductsAugmentation = createAction(
    GET_PRODUCTS_AUGMENTATION,
    props<{ sku: string[] }>()
);
export const getProductsAugmentationSuccess = createAction(
    GET_PRODUCTS_AUGMENTATION_SUCCESS,
    props<{ productsAugmentation: ChartData[] }>()
);
export const getProductsAugmentationFailure = createAction(GET_PRODUCTS_AUGMENTATION_FAILURE);
export const clearProductsAugmentation = createAction(CLEAR_PRODUCTS_AUGMENTATION);