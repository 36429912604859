export enum UserRole {
  USER = "USER",
  OPERATIONS = "OPERATIONS",
  ADMIN = "ADMIN"
}

export class UserApiDto {
  id: string;
  token: string;
  profile: UserProfileDto;
  company: UserCompanyDto;

  constructor(
    id: string,
    token: string,
    profile: UserProfileDto,
    company: UserCompanyDto,
  ) {
    this.id = id;
    this.token = token;
    this.profile = profile;
    this.company = company;
  }
}

export class UserProfileDto {
  username: string;
  email: string;
  role: UserRole;

  constructor(username: string, email: string, role: UserRole) {
    this.username = username;
    this.email = email;
    this.role = role;
  }
}

export class UserCompanyDto {
  id: string;
  name: string;

  constructor(id: string, name: string) {
    this.id = id;
    this.name = name;
  }
}
