import { createAction, props } from "@ngrx/store";
import { SaleOrder, SaleOrderItem } from "../states/sale-order.state";
import { Product } from "../states/individual-products.state";
import { Client } from "../states/clients.state";
import { CreateSaleOrderDto, SaleOrderDto } from "../../common/api-dtos/responses/sale-order.dto";

export const GET_SALE_ORDER = '[Sale Order] Get Sale Order';
export const GET_SALE_ORDER_SUCCESS = '[Sale Order] Get Sale Order Success';
export const GET_SALE_ORDER_FAILURE = '[Sale Order] Get Sale Order Failure';
export const CLEAR_SALE_ORDER = '[Sale Order] Sale Order Clear';
export const ORDER_ITEM_UPDATE = '[Sale Order] Sale Order Local Item Update';
export const ORDER_ITEM_LOCAL_ADD = '[Sale Order] Sale Order Local Item Add';
export const ORDER_ITEM_LOCAL_REMOVE = '[Sale Order] Sale Order Local Item Remove';
export const ORDER_CLIENT_LOCAL_ADD = '[Sale Order] Sale Order Local Client Add';
export const PROCESS_SALE_ORDER = '[Sale Order] Process Sale Order';
export const PROCESS_SALE_ORDER_SUCCESS = '[Sale Order] Process Sale Order Success';
export const PROCESS_SALE_ORDER_FAILURE = '[Sale Order] Process Sale Order Failure';
export const UPDATE_SALE_ORDER = '[Sale Order] Update Sale Order';
export const UPDATE_SALE_ORDER_SUCCESS = '[Sale Order] Update Sale Order Success';
export const UPDATE_SALE_ORDER_FAILURE = '[Sale Order] Update Sale Order Failure';
export const CREATE_SALE_ORDER = '[Sale Order] Create Sale Order';
export const CREATE_SALE_ORDER_SUCCESS = '[Sale Order] Create Sale Order Success';
export const CREATE_SALE_ORDER_FAILURE = '[Sale Order] Create Sale Order Failure';
export const DOWNLOAD_ESTIMATE = '[Sale Order] Download Estimate';
export const UPDATE_PRODUCT_PRICE = '[Sale Order] Update Product Price';

export const downloadEstimate = createAction(DOWNLOAD_ESTIMATE, props<{ id: string }>());

export const createSaleOrder = createAction(CREATE_SALE_ORDER);
export const createSaleOrderSuccess = createAction(CREATE_SALE_ORDER_SUCCESS, props<{ order: CreateSaleOrderDto }>());
export const createSaleOrderFailure = createAction(CREATE_SALE_ORDER_FAILURE);
export const getSaleOrder = createAction(
  GET_SALE_ORDER,
  props<{ id: string }>()
);
export const getSaleOrderSuccess = createAction(
  GET_SALE_ORDER_SUCCESS,
  props<{ order: SaleOrder }>()
);
export const getSaleOrderFailure = createAction(GET_SALE_ORDER_FAILURE);
export const clearSaleOrder = createAction(CLEAR_SALE_ORDER);

export const localOrderItemUpdate = createAction(
  ORDER_ITEM_UPDATE,
  props<{ item: SaleOrderItem }>()
);
export const localAddItemToOrder = createAction(
  ORDER_ITEM_LOCAL_ADD,
  props<{ item: Product }>()
);
export const localRemoveItemFromOrder = createAction(
  ORDER_ITEM_LOCAL_REMOVE,
  props<{ item: SaleOrderItem }>()
);
export const localAddClientToOrder = createAction(
  ORDER_CLIENT_LOCAL_ADD,
  props<{ client: Client }>()
)
export const updateSaleOrder = createAction(
  UPDATE_SALE_ORDER
)
export const updateSaleOrderSuccess = createAction(
  UPDATE_SALE_ORDER_SUCCESS,
  props<{ order: SaleOrder }>()
)
export const updateSaleOrderFailure = createAction(
  UPDATE_SALE_ORDER_FAILURE
)
export const processSaleOrder = createAction(
  PROCESS_SALE_ORDER
)
export const processSaleOrderSuccess = createAction(
  PROCESS_SALE_ORDER_SUCCESS,
  props<{ order: SaleOrder }>()
)
export const processSaleOrderFailure = createAction(
  PROCESS_SALE_ORDER_FAILURE
)
export const updateSaleOrderProductPrice = createAction(
  UPDATE_PRODUCT_PRICE,
  props<{ item: SaleOrderItem, id: string }>()
)