import {Product} from "./individual-products.state";
import {createFeatureSelector, createSelector} from "@ngrx/store";

export interface GroupedProduct {
  products: Product[]
  tag: string;
}

export interface GroupedProductsState {
  groupedProducts: GroupedProduct[];
  totalProducts: number;
}

export const initialGroupedProductsState: GroupedProductsState = {
  groupedProducts: [],
  totalProducts: 0
};


export const SELECT_GROUPED_PRODUCTS = 'groupedProducts'
export const selectGroupedProductsState = createFeatureSelector<GroupedProductsState>(SELECT_GROUPED_PRODUCTS);

export const selectGroupedProducts = createSelector(
  selectGroupedProductsState,
  (state: GroupedProductsState) => state
);
