import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ChartData } from "../../common/api-dtos/responses/product-augmentation.dto";

export interface ProviderAugmentationState {
    provider: string;
    averageAugmentationPercentage: number;
}

export interface ProductAugmentationState {
    sku: string;
    provider: string;
    averageAugmentationPercentage: number;
}

export interface HistoricalPriceMap {
    data : {
        year: string;
    }
    leaf: boolean;
    children: {
        data : {
            month: string;
        }
        leaf: boolean;
        children: {
            data : {
                day: string;
                price: string;
            }
        }[];
    }[];
}

export interface DashboardState {
    providerAugmentations: ProviderAugmentationState[][];
    productAugmentations: ChartData[];
    historicalPriceMap: HistoricalPriceMap[];
    historicalPriceMapLoading: boolean;
}

export const initialDashboardState: DashboardState = {
    providerAugmentations: [],
    productAugmentations: [],
    historicalPriceMap: [],
    historicalPriceMapLoading: false
}

export const SELECT_DASHBOARD_STATE = 'dashboard'
export const selectDashboard =
  createFeatureSelector<DashboardState>(SELECT_DASHBOARD_STATE);

export const selectDashboardSelector = createSelector(
    selectDashboard,
    (state: DashboardState) => state
);