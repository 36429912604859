import {createAction, props} from "@ngrx/store";
import { ParsePriceFile } from "../states/parsed-price-file.state";

export const GET_PARSED_PRICE_FILE = '[Files] Get Parsed price file';
export const GET_FILES_SUCCESS = '[Files] Get Parsed price file Success';
export const GET_FILES_FAILURE = '[Files] Get Parsed price file Failure';
export const CLEAR_FILES = '[Files] Parsed Files Clear';
export const UPDATE_ITEM_PRICE_BY_FILE_ID = '[Files] Update item price by parsed price file id';
export const UPDATE_ITEM_PRICE_BY_FILE_ID_SUCCESS = '[Files] Update item price by parsed price file id success';
export const UPDATE_ITEM_PRICE_BY_FILE_ID_FAILURE = '[Files] Update item price by parsed price file id failure';

export const getParsedPriceFile = createAction(
  GET_PARSED_PRICE_FILE,
  props<{ id: string }>()
);
export const getParsedPriceFileSuccess = createAction(
  GET_FILES_SUCCESS,
  props<{ file: ParsePriceFile }>()
);
export const getParsedPriceFileFailure = createAction(GET_FILES_FAILURE);
export const updateItemPriceByPricedFileId = createAction(
  UPDATE_ITEM_PRICE_BY_FILE_ID,
  props<{ itemId: string, fileId: string }>()
);
export const updateItemPriceByPricedFileIdSuccess = createAction(UPDATE_ITEM_PRICE_BY_FILE_ID_SUCCESS);
export const updateItemPriceByPricedFileIdFailure = createAction(UPDATE_ITEM_PRICE_BY_FILE_ID_FAILURE);
export const clearParsedPriceFile = createAction(CLEAR_FILES);
