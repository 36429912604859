import { createFeatureSelector, createSelector } from "@ngrx/store";

export interface StoreProduct {
    id: string;
    name: string;
    description: string;
    price: number;
    hidden: boolean;
    image: string[];
    availableStock: number;
    variants: {
        title: string;
        items: {
            itemStoreRef: string;
            title: string;
        }[];
    }[];
    items: {
        item: {
            id: string;
            name: string;
            stockPrice: number;
            sku: string;
        }
        quantity: number;
    }[];
    createdBy: string;
}

export interface StoreState {
    products: StoreProduct[],
    totalProducts: number;
}

export const initialStoreState: StoreState = {
    products: [],
    totalProducts: 0
};

const SELECT_STORE_STATE = 'store'
const selectStore = createFeatureSelector<StoreState>(SELECT_STORE_STATE);

export const selectStoreSelector = createSelector(
    selectStore,
    (state: StoreState) => state
);