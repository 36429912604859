import { createFeatureSelector, createSelector } from "@ngrx/store";
import { SearchParametersDto } from "../../common/api-dtos/requests/search-parameters.dto";

export interface ParsedStockFile {
  id: string;
  status: string;
  createdBy: string;
  createdAt: Date;
  parsedData: ParsedstockData[];
}

export interface ParsedstockData {
  code: string;
  description: string;
  isOnStock: boolean;
  stockItemId?: string;
  ammount: number;
  updatedStockBy?: {
    id: string;
    name: string;
  };
}

export interface ParseStockFileState {
  file: ParsedStockFile | null;
  loading: boolean,
  error: string | null,
}

export const initialParseStockFilesState: ParseStockFileState = {
  file: null,
  loading: false,
  error: null
}

export const SELECT_PARSED_STOCK_FILE = 'parsedStockFile'
export const selectParsedStockFileState =
  createFeatureSelector<ParseStockFileState>(SELECT_PARSED_STOCK_FILE);

export const selectParsedStockFileSelector = createSelector(
  selectParsedStockFileState,
  (state: ParseStockFileState) => state
);