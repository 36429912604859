import {createAction, props} from "@ngrx/store";
import {SearchParametersDto} from "../../common/api-dtos/requests/search-parameters.dto";
import { TableSaleOrder } from "../states/sale-orders.state";

export const GET_SALE_ORDERS = '[Sale Orders] Get Sale Orders';
export const GET_SALE_ORDERS_SUCCESS = '[Sale Orders] Get Sale Orders Success';
export const GET_SALE_ORDERS_FAILURE = '[Sale Orders] Get Sale Orders Failure';
export const CLEAR_SALE_ORDERS = '[Sale Orders] Sale Orders Clear';

export const getSaleOrders = createAction(
  GET_SALE_ORDERS,
  props<{ searchParameters: SearchParametersDto }>()
);
export const getSaleOrdersSuccess = createAction(
  GET_SALE_ORDERS_SUCCESS,
  props<{ orders: TableSaleOrder[], totalOrders: number }>()
);
export const getSaleOrdersFailure = createAction(GET_SALE_ORDERS_FAILURE);
export const clearSaleOrders = createAction(CLEAR_SALE_ORDERS);