import { createFeatureSelector, createSelector } from "@ngrx/store";

export interface TableSaleOrder {
    id: string;
    ticketReference: string;
    client: string | null;
    status: string; // TODO: Type status here
    ammount: number;
    paymentType: string; // TODO: Type Payment Status here
    soldBy: string;
    createdAt: Date;
}

export interface SaleOrdersState {
    loading: boolean;
    saleOrders: TableSaleOrder[];
    totalSaleOrders: number;
}

export const initTableSaleOrderState : SaleOrdersState = {
    loading: false,
    saleOrders: [],
    totalSaleOrders: 0,
}

export const SELECT_SALE_ORDERS = 'saleOrders'
export const selectSaleOrders = createFeatureSelector<SaleOrdersState>(SELECT_SALE_ORDERS);

export const selectSaleOrdersSelector  = createSelector(
    selectSaleOrders,
  (state: SaleOrdersState) => state
);