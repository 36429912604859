import { createFeatureSelector, createSelector } from "@ngrx/store";

export interface Client {
    id?: string;
    legalName: string;
    address: string;
    city: string;
    postalCode: string;
    email?: string;
    phoneNumber: string;
    documentationType: string;
    documentationNumber: number;
}

export interface ClientsState {
    loading: boolean;
    clients: Client[] | null;
}

export const initClientsState : ClientsState = {
    loading: false,
    clients: [],
}

export const SELECT_CLIENTS = 'clients'
export const selectClients = createFeatureSelector<ClientsState>(SELECT_CLIENTS);

export const selectClientsSelector  = createSelector(
    selectClients,
    (state: ClientsState) => state
);