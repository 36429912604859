import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {Observable} from "rxjs";
import {HttpRequestHelper} from "../../common/helpers/http-request.helper";
import {SearchParametersDto} from "../../common/api-dtos/requests/search-parameters.dto";
import {ItemDto} from "../../common/api-dtos/responses/products.dto";
import { LowStockProductResponseDto } from "../../common/api-dtos/responses/low-stock-products.dto";
import { CreateProductDto } from "../../common/api-dtos/requests/create-item.dto";
import { UpdateProductDto } from "../../common/api-dtos/requests/update-item.dto";

@Injectable({ providedIn: 'root' })
export class ProductsService {

  constructor(
    private readonly http: HttpClient
  ) {}

  private readonly BASE_URL = environment.BACKEND_URL || "localhost:3000"
  private readonly PRODUCTS_ENDPOINT = `${this.BASE_URL}/v1/products`

  getGroupedItems(searchTerm: SearchParametersDto): Observable<{ products: { tag: string, items: ItemDto[] }[], totalProducts: number }> {
    const headers = HttpRequestHelper.getHttpHeaders();
    const options = HttpRequestHelper.getHttpOptions(headers, searchTerm);
    return this.http.get<{products: { tag: string, items: ItemDto[] }[], totalProducts: number }>(`${this.PRODUCTS_ENDPOINT}/groups`, options);
  }

  getItems(searchTerm: SearchParametersDto): Observable<{ products: ItemDto[], total: number }> {
    const headers = HttpRequestHelper.getHttpHeaders();
    const options = HttpRequestHelper.getHttpOptions(headers, searchTerm);
    return this.http.get<{ products: ItemDto[], total: number }>(`${this.PRODUCTS_ENDPOINT}`, options);
  }

  updatePriceByPricedFileId(itemId: string, fileId: string) {
    const headers = HttpRequestHelper.getHttpHeaders();
    const options = HttpRequestHelper.getHttpOptions(headers);
    options['params'] = { fileId };
    return this.http.patch<ItemDto[]>(`${this.PRODUCTS_ENDPOINT}/${itemId}/update-price-by-file`, {}, options);
  }

  getLowStockProducts(searchParameters: SearchParametersDto) {
    const headers = HttpRequestHelper.getHttpHeaders();
    const options = HttpRequestHelper.getHttpOptions(headers);
    options['params'] = searchParameters;
    return this.http.get<LowStockProductResponseDto>(`${this.PRODUCTS_ENDPOINT}/low-stock`, options);
  }

  createItem(item: CreateProductDto) {
    const headers = HttpRequestHelper.getHttpHeaders();
    const options = HttpRequestHelper.getHttpOptions(headers);
    return this.http.post<void>(`${this.PRODUCTS_ENDPOINT}`, item, options);
  }

  updateProduct(id: string, item: UpdateProductDto) {
    const headers = HttpRequestHelper.getHttpHeaders();
    const options = HttpRequestHelper.getHttpOptions(headers);
    return this.http.patch<ItemDto>(`${this.PRODUCTS_ENDPOINT}/${id}`, item, options);
  }

  deleteItem(id: string) {
    const headers = HttpRequestHelper.getHttpHeaders();
    const options = HttpRequestHelper.getHttpOptions(headers);
    return this.http.delete<void>(`${this.PRODUCTS_ENDPOINT}/${id}`, options);
  }

  updateStockByParsedFileId(itemId: string, fileId: string) {
    const headers = HttpRequestHelper.getHttpHeaders();
    const options = HttpRequestHelper.getHttpOptions(headers);
    options['params'] = { fileId };
    return this.http.patch<ItemDto[]>(`${this.PRODUCTS_ENDPOINT}/${itemId}/update-stock-by-file`, {}, options);
  }

}