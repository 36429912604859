import {createAction, props} from "@ngrx/store";
import { SearchParametersDto } from "../../common/api-dtos/requests/search-parameters.dto";
import { Provider } from "../states/providers.state";


export const GET_PROVIDERS = '[Providers] Get Providers';
export const GET_PROVIDERS_SUCCESS = '[Providers] Get Providers Success';
export const GET_PROVIDERS_FAILURE = '[Providers] Get Providers Failure';
export const CLEAN_PROVIDERS = '[Providers] Clean providers list';

export const getProvidersList = createAction(
    GET_PROVIDERS,
  props<{ request: SearchParametersDto }>()
);
export const getProvidersListSuccess = createAction(
    GET_PROVIDERS_SUCCESS,
  props<{ providers: Provider[] }>()
);
export const getProvidersListFailure = createAction(GET_PROVIDERS_FAILURE);
export const cleanProvidersList = createAction(CLEAN_PROVIDERS);
