export class SearchParametersDto {
  searchTerm?: string;
  limit?: number;
  offset?: number;

  constructor(
    constructor: {
      searchTerm?: string;
      limit: number;
      page: number;
    }
  ) {
    this.searchTerm = constructor.searchTerm;
    this.limit = constructor.limit;
    this.offset = SearchParametersDto.calculateOffset(constructor.limit, constructor.page);
  }

  private static calculateOffset(limit: number, page: number) {
    return (page - 1) * limit;
  }

}