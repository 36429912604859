import { createFeatureSelector, createSelector } from "@ngrx/store";
import { SearchParametersDto } from "../../common/api-dtos/requests/search-parameters.dto";

export interface LowStockProduct {
    code: string;
    description: string;
    stock: number;
    stockAlert: number;
    id: string;
    stockPrice: number;
    provider: {
      name: string;
      id: string;
    }
}

export interface LowStockProductState {
  ammountOfLowStockProducts: number;
  lowStockProducts: LowStockProduct[];
}

export const initialLowStockProducts: LowStockProductState = {
    ammountOfLowStockProducts: 0,
    lowStockProducts: []
}

export const SELECT_LOW_STOCK_PRODUCTS = 'lowStockProducts'
export const selectLowStockProducts =
  createFeatureSelector<LowStockProductState>(SELECT_LOW_STOCK_PRODUCTS);

export const selectLowStockProductsSelector = createSelector(
  selectLowStockProducts,
  (state: LowStockProductState) => state
);