import { createFeatureSelector, createSelector } from "@ngrx/store";

export interface Provider {
    id: string;
    name: string;
}

export interface ProviderState {
    providers: Provider[];
    loading: boolean,
    error: string | null,
}

export const initialProvidersState: ProviderState = {
    providers: [],
    loading: false,
    error: null
}

export const SELECT_PROVIDERS = 'providers'
export const selectProviderState =
    createFeatureSelector<ProviderState>(SELECT_PROVIDERS);

export const selectProviderSelector = createSelector(
    selectProviderState,
    (state: ProviderState) => state
);