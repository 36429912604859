import {createAction, props} from "@ngrx/store";
import {SearchParametersDto} from "../../common/api-dtos/requests/search-parameters.dto";
import {Product} from "../states/individual-products.state";
import { ICreateIndividualProduct } from "../../common/interfaces/create-individual-product.interface";
import { UpdateProductDto } from "../../common/api-dtos/requests/update-item.dto";

export const GET_INDIVIDUAL_PRODUCTS = '[Individual Products] Get Individual Products';
export const GET_INDIVIDUAL_PRODUCTS_SUCCESS = '[Individual Products] Get Individual Products Success';
export const GET_INDIVIDUAL_PRODUCTS_FAILURE = '[Individual Products] Get Individual Products Failure';
export const CLEAR_INDIVIDUAL_PRODUCTS_LIST = '[Individual Products] Clear';
export const CREATE_INDIVIDUAL_PRODUCT = '[Individual Products] Create Individual Product';
export const CREATE_INDIVIDUAL_PRODUCT_SUCCESS = '[Individual Products] Create Individual Product Success';
export const CREATE_INDIVIDUAL_PRODUCT_FAILURE = '[Individual Products] Create Individual Product Failure';

export const getIndividualProducts = createAction(
  GET_INDIVIDUAL_PRODUCTS,
  props<{ searchParameters: SearchParametersDto }>()
);
export const getIndividualProductsSuccess = createAction(
  GET_INDIVIDUAL_PRODUCTS_SUCCESS,
  props<{ products: Product[], totalProducts: number }>()
);
export const getIndividualProductsFailure = createAction(
  GET_INDIVIDUAL_PRODUCTS_FAILURE
);
export const clearIndividualProductsList = createAction(CLEAR_INDIVIDUAL_PRODUCTS_LIST);
export const createIndividualProduct = createAction(
  CREATE_INDIVIDUAL_PRODUCT,
  props<{ 
    product: ICreateIndividualProduct,
    successRedirect?: string
  }>()
);
export const createIndividualProductSuccess = createAction(
  CREATE_INDIVIDUAL_PRODUCT_SUCCESS,
  props<{ successRedirect?: string }>()
);
export const createIndividualProductFailure = createAction(CREATE_INDIVIDUAL_PRODUCT_FAILURE);

export const UPDATE_INDIVIDUAL_PRODUCT = '[Individual Products] Update Individual Product';
export const UPDATE_INDIVIDUAL_PRODUCT_SUCCESS = '[Individual Products] Update Individual Product Success';
export const UPDATE_INDIVIDUAL_PRODUCT_FAILURE = '[Individual Products] Update Individual Product Failure';
export const updateIndividualProduct = createAction(
  UPDATE_INDIVIDUAL_PRODUCT,
  props<{ id: string, product: UpdateProductDto }>()
);
export const updateIndividualProductSuccess = createAction(
  UPDATE_INDIVIDUAL_PRODUCT_SUCCESS,
  props<{ product: Product }>()
);
export const updateIndividualProductFailure = createAction(UPDATE_INDIVIDUAL_PRODUCT_FAILURE);

export const DELETE_INDIVIDUAL_PRODUCT = '[Individual Products] Delete Individual Product';
export const DELETE_INDIVIDUAL_PRODUCT_SUCCESS = '[Individual Products] Delete Individual Product Success';
export const DELETE_INDIVIDUAL_PRODUCT_FAILURE = '[Individual Products] Delete Individual Product Failure';

export const deleteIndividualProduct = createAction(
  DELETE_INDIVIDUAL_PRODUCT,
  props<{ id: string }>()
);
export const deleteIndividualProductSuccess = createAction(
  DELETE_INDIVIDUAL_PRODUCT_SUCCESS,
);
export const deleteIndividualProductFailure = createAction(
  DELETE_INDIVIDUAL_PRODUCT_FAILURE
);
