import { createFeatureSelector, createSelector } from "@ngrx/store";

export interface SaleOrderStockItem {
    id: string;
    price: number;
    commission: number;
    sellPrice: number;
    description: string;
    sku: string;
}

export interface SaleOrderItem {
    soldPrice: number;
    finalSoldPrice: number;
    quantity: number;
    discountType: 'PERCENTAGE' | 'TOTAL';
    discountAmmount: number;
    stockItem: SaleOrderStockItem;
}

export interface SaleOrder {
    id: string;
    ticketReference: string;
    client: SaleOrderClient | null;
    status: string; // TODO: Type status here
    paymentStatus: string;
    paymentType: string; // TODO: Type Payment Status here
    soldBy: string;
    createdAt: Date;
    items: SaleOrderItem[]
}

export interface SaleOrderClient {
    id?: string;
    legalName: string;
    address: string;
    city: string;
    postalCode: string;
    email?: string;
    phoneNumber: string;
    documentationType: string;
    documentationNumber: number;
}

export interface SaleOrderState {
    loading: boolean;
    saleOrder: SaleOrder | null;
    pendingChanges: boolean;
    totalSaleOrders: number;
}

export const initSaleOrderState : SaleOrderState = {
    loading: false,
    saleOrder: null,
    pendingChanges: false,
    totalSaleOrders: 0,
}

export const SELECT_SALE_ORDER = 'saleOrder'
export const selectSaleOrder = createFeatureSelector<SaleOrderState>(SELECT_SALE_ORDER);

export const selectSaleOrderSelector  = createSelector(
    selectSaleOrder,
  (state: SaleOrderState) => state
);