import { Injectable } from "@angular/core";
import { SearchParametersDto } from "../../common/api-dtos/requests/search-parameters.dto";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { HttpRequestHelper } from "../../common/helpers/http-request.helper";
import { ProviderDto } from "../../common/api-dtos/responses/provider.dto";

@Injectable({ providedIn: 'root' })
export class StoreService {
    
    constructor(
        private readonly http: HttpClient
    ) {}

    private readonly BASE_URL = environment.BACKEND_URL || "localhost:3000"
    private readonly STORE_URL = `${this.BASE_URL}/v1/store`

    getStoreProducts(params: SearchParametersDto) {
        const headers = HttpRequestHelper.getHttpHeaders(params);
        const options = HttpRequestHelper.getHttpOptions(headers, params);
        return this.http.get<{ totalProducts: number, products: ProviderDto[] }>(`${this.STORE_URL}/items`, options);
    }

    createStoreProduct(product: any) {
        const headers = HttpRequestHelper.getHttpHeaders(product);
        const options = HttpRequestHelper.getHttpOptions(headers, product);
        return this.http.post(`${this.STORE_URL}/items`, product, options);
    }

    getSignedUrl() {
        const headers = HttpRequestHelper.getHttpHeaders();
        const options = HttpRequestHelper.getHttpOptions(headers);
        return this.http.get<{ url: string, fileName: string }>(`${this.STORE_URL}/items/upload-picture-signed-url`, options);
    }

    getDownloadSignedUrl(fileName: string) {
        const headers = HttpRequestHelper.getHttpHeaders();
        const options = HttpRequestHelper.getHttpOptions(headers);
        return this.http.get<{ url: string }>(`${this.STORE_URL}/items/download-picture-signed-url?fileName=${fileName}`, options);
    }

    deleteStoreProduct(id: string) {
        const headers = HttpRequestHelper.getHttpHeaders();
        const options = HttpRequestHelper.getHttpOptions(headers);
        return this.http.delete(`${this.STORE_URL}/items/${id}`, options);
    }

}