import {createFeatureSelector, createSelector} from "@ngrx/store";
import {AppState} from "./index";

export enum TagType {
  INDIVIDUAL = "INDIVIDUAL",
  GROUP = "GROUP",
}

export interface Tag {
  type: TagType;
  name: string;
}

export interface Product {
  id: string;
  sku: string;
  description: string;
  stock: number;
  blockedStock: number;
  stockMinQuantity: number;
  stockPrice: number;
  commission: number;
  salePrice: number;
  tags: Tag[];
  provider?: Provider
}

export interface Provider {
  id: string;
  name: string;
  onboarded: boolean;
}

export interface IndividualProductsState {
  individualProducts: Product[];
  totalProducts: number;
}

export const initialIndividualProductsState: IndividualProductsState = {
  individualProducts: [],
  totalProducts: 0
};


export const SELECT_INDIVIDUAL_PRODUCTS = 'individualProducts'
export const selectIndividualProductsState =
  createFeatureSelector<IndividualProductsState>(SELECT_INDIVIDUAL_PRODUCTS);

export const selectIndividualProducts = createSelector(
  selectIndividualProductsState,
  (state: IndividualProductsState) => state
);