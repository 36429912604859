<div class="min-h-screen flex surface-ground">
    <!-- Sidebar for desktop view -->
    <div 
        id="app-sidebar"
        class="h-screen hidden lg:block flex-shrink-0 fixed lg:sticky left-0 top-0 z-1 select-none w-15rem bg-white-100 animation-duration-300 animation-ease-in-out">
        <ng-container *ngTemplateOutlet="navbar"></ng-container>
    </div>

    <div class="min-h-screen flex flex-column flex-auto">

        <div class="lg:hidden block w-full">
            <div class="flex justify-content-between align-items-center px-5 surface-section shadow-2 border-bottom-1 surface-border"
                 style="height:60px">
                <div>
                    <i *ngIf="!burgerMenuOpen" (click)="openMobileMenu()" class="fa-light fa-bars"></i>
                    <i *ngIf="burgerMenuOpen" (click)="closeMobileMenu()" class="fa-light fa-bars"></i>
                </div>
                <div>
                    <span>My Stock Seguro</span>
                </div>
            </div>
        </div>

        <div class="p-5 flex flex-column flex-auto">
            <div *ngIf="!burgerMenuOpen">
                <router-outlet></router-outlet>
            </div>
            <div *ngIf="burgerMenuOpen">
                <ng-container *ngTemplateOutlet="navbar"></ng-container>
            </div>
        </div>
    </div>
</div>

<ng-template #navbar>
    <div class="flex flex-column h-full justify-content-between side-bar-items">
        <!-- Sidebar top items -->
        <div>
            <!-- Header-->
            <div class="grid mt-2">
                <div class="col-12 flex justify-content-center align-items-center text-center">
                    <div class="logo-placeholder"></div>
                </div>
                <div class="col-12 justify-content-center align-items-center text-center">
                    <h1 class="text-xl">{{  ( user$ | async )?.user?.username }}</h1>
                    <p class="text-sm">{{ (( user$ | async )?.user?.email || "")  | ellipsis }}</p>
                </div>
            </div>
            <hr class="mb-3 mx-3 border-top-1 border-none surface-border" />
            <!-- Mi Empresa -->
            <ul class="list-none p-3 m-0">
                <li>
                    <div pRipple class="p-3 flex align-items-center justify-content-between text-600 cursor-pointer"
                        pStyleClass="@next" enterClass="hidden" enterActiveClass="slidedown" leaveToClass="hidden"
                        leaveActiveClass="slideup">
                        <span class="font-medium">MI EMPRESA</span>
                        <i class="fa-light fa-chevron-down"></i>
                    </div>
                    <ul class="list-none p-0 m-0 overflow-hidden">
                        <li>
                            <a pRipple [ngClass]="getItemClasses('/app/home')" href="/app/home">
                                <i class="fa-light fa-house mr-2"></i>
                                <span class="font-medium">Inicio</span>
                            </a>
                        </li>
                        <li>
                            <a pRipple [ngClass]="getItemClasses('/app/inventory')" href="/app/inventory">
                                <i class="fa-light fa-boxes-stacked mr-2"></i>
                                <span class="font-medium">Inventario </span>
                            </a>
                        </li>
                        <li>
                            <a pRipple [ngClass]="getItemClasses('/app/files')" href="/app/files">
                                <i class="fa-light fa-files mr-2"></i>
                                <span class="font-medium">Archivos</span>
                                <span
                                    *ngIf="((fileState$ | async)?.totalPendingDocuments || 0) > 0"
                                    class="inline-flex align-items-center justify-content-center ml-auto bg-yellow-300 text-0 border-circle"
                                    style="min-width: 1rem; height: 1rem"></span>
                            </a>
                        </li>
                        <li>
                            <a pRipple [ngClass]="getItemClasses('/app/purchase-orders')" href="/app/purchase-orders">
                                <i class="fa-light fa-cash-register mr-2"></i>
                                <span class="font-medium">Ordenes de compra</span>
                            </a>
                        </li>
                        <li>
                            <a pRipple [ngClass]="getItemClasses('/app/sale-order')" href="/app/sale-orders">
                                <i class="fa-light fa-cash-register mr-2"></i>
                                <span class="font-medium">Ordenes de venta</span>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
            <!-- Tienda virtual-->
            <ul class="list-none p-3 m-0">
                <li>
                    <div pRipple class="p-3 flex align-items-center justify-content-between text-600 cursor-pointer"
                        pStyleClass="@next" enterClass="hidden" enterActiveClass="slidedown" leaveToClass="hidden"
                        leaveActiveClass="slideup">
                        <span class="font-medium">MI TIENDA VIRTUAL</span>
                        <i class="fa-light fa-chevron-down"></i>
                    </div>
                    <ul class="list-none p-0 m-0 overflow-hidden">
                        <li>
                            <a pRipple [ngClass]="getItemClasses('/app/store')" href="/app/store">
                                <i class="fa-light fa-boxes-stacked mr-2"></i>
                                <span class="font-medium">Productos</span>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>

            <!-- Admin Panel -->
            <ul class="list-none p-3 m-0" *ngIf="isAdmin$ | async">
                <li>
                    <div pRipple class="p-3 flex align-items-center justify-content-between text-600 cursor-pointer"
                        pStyleClass="@next" enterClass="hidden" enterActiveClass="slidedown" leaveToClass="hidden"
                        leaveActiveClass="slideup">
                        <span class="font-medium">ADMIN</span>
                        <i class="fa-light fa-chevron-down"></i>
                    </div>
                    <ul class="list-none p-0 m-0 overflow-hidden">
                        <li>
                            <a pRipple [ngClass]="getItemClasses('/admin/tasks')" href="/admin/tasks">
                                <i class="fa-light fa-list-check mr-2"></i>
                                <span class="font-medium">Task List</span>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>

        <!-- LOGOUT -->
        <ul class="list-none p-3 m-0 mt-auto">
            <li>
                <a pRipple (click)="logout()">
                    <i class="fa-light fa-sign-out-alt mr-2"></i>
                    <span class="font-medium">Cerrar sesión</span>
                </a>
            </li>
        </ul>
    </div>
</ng-template>