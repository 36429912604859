import {createAction, props} from "@ngrx/store";
import { ParsePriceFile } from "../states/parsed-price-file.state";
import { ParsedStockFile } from "../states/parsed-stock-file.state";

export const GET_PARSED_STOCK_FILE = '[Files] Get Parsed stock file';
export const GET_PARSED_STOCK_FILE_SUCCESS = '[Files] Get Parsed price stock Success';
export const GET_PARSED_STOCK_FILE_FAILURE = '[Files] Get Parsed price stock Failure';
export const CLEAR_PARSED_STOCK_FILE = '[Files] Stock Parsed File Clear';

export const getParsedStockFile = createAction(
  GET_PARSED_STOCK_FILE,
  props<{ id: string }>()
);
export const getParsedStockFileSuccess = createAction(
  GET_PARSED_STOCK_FILE_SUCCESS,
  props<{ file: ParsedStockFile }>()
);
export const getParsedStockFileFailure = createAction(GET_PARSED_STOCK_FILE_FAILURE);
export const clearParsedStockFile = createAction(CLEAR_PARSED_STOCK_FILE);

export const UPDATE_ITEM_STOCK_BY_PARSED_FILE_ID = '[Files] Update Item Stock By Parsed File Id';
export const UPDATE_ITEM_STOCK_BY_PARSED_FILE_ID_SUCCESS = '[Files] Update Item Stock By Parsed File Id Success';
export const UPDATE_ITEM_STOCK_BY_PARSED_FILE_ID_FAILURE = '[Files] Update Item Stock By Parsed File Id Failure';

export const updateItemStockByParsedFileId = createAction(
  UPDATE_ITEM_STOCK_BY_PARSED_FILE_ID,
  props<{ itemId: string, fileId: string }>()
);
export const updateItemStockByParsedFileIdSuccess = createAction(UPDATE_ITEM_STOCK_BY_PARSED_FILE_ID_SUCCESS);
export const updateItemStockByParsedFileIdFailure = createAction(UPDATE_ITEM_STOCK_BY_PARSED_FILE_ID_FAILURE);