import {createAction, props} from "@ngrx/store";
import { SearchParametersDto } from "../../common/api-dtos/requests/search-parameters.dto";
import { Client } from "../states/clients.state";

export const GET_CLIENTS = '[Clients] Get Company Clients';
export const GET_CLIENTS_SUCCESS = '[Clients] Get Company Clients Success';
export const GET_CLIENTS_FAILURE = '[Clients] Get Company Clients Failure';
export const CLEAR_CLIENTS = '[Clients] Clients Clear';

export const getClients = createAction(
    GET_CLIENTS,
    props<{ searchTerm: SearchParametersDto }>()
);
export const getClientsSuccess = createAction(
    GET_CLIENTS_SUCCESS,
    props<{ clients: Client[] }>()
);
export const getClientsFailure = createAction(GET_CLIENTS_FAILURE);
export const clearClients = createAction(CLEAR_CLIENTS);