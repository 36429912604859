import { createAction, props } from '@ngrx/store';
import { LowStockProductState } from '../states/low-stock-products.state';
import { SearchParametersDto } from '../../common/api-dtos/requests/search-parameters.dto';

export const LOW_STOCK_PRODUCTS = '[Low Stock Products] Get Low Stock Products';
export const LOW_STOCK_PRODUCTS_SUCCESS = '[Low Stock Products] Get Low Stock Products Success';
export const LOW_STOCK_PRODUCTS_FAILURE = '[Low Stock Products] Get Low Stock Products Failure';
export const CLEAR_LOW_STOCK_PRODUCTS = '[Low Stock Products] Clear Low Stock Products';

export const getLowStockProducts = createAction(
    LOW_STOCK_PRODUCTS,
    props<{ searchParameters: SearchParametersDto }>()
);

export const getLowStockProductsSuccess = createAction(
    LOW_STOCK_PRODUCTS_SUCCESS,
    props<{ lowStockProducts: LowStockProductState }>()
);
export const getLowStockProductsFailure = createAction(LOW_STOCK_PRODUCTS_FAILURE);
export const clearLowStockProducts = createAction(CLEAR_LOW_STOCK_PRODUCTS);
